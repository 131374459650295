<script setup lang="ts">
import type { DropDownItem } from '@rialtic/types'
import { ALL_LOB } from '~/constants'
import { useWorkspace } from '~/stores/workspace'

defineProps<{
  mini?: boolean
  primary?: boolean
}>()

const router = useRouter()
const workspace = useWorkspace()

const allLOBItem: DropDownItem = {
  itemValue: ALL_LOB,
  label: 'Aggregate dashboard',
}
const connectorItems = computed((): DropDownItem[] => [
  ...(workspace.hasAggregateConnectors ? [allLOBItem] : []),
  ...workspace.connectors.map(({ id, name: label }) => ({
    label,
    itemValue: id,
  })),
])

const activeId = ref('')

const active = computed({
  get: () => {
    if (workspace.connectorId === ALL_LOB) {
      return allLOBItem
    }

    if (!workspace.activeConnector) {
      return { label: '', itemValue: '' }
    }

    const { id, name } = workspace.activeConnector

    return {
      label: name || '',
      itemValue: id || '',
    }
  },
  set: (_value) => {},
})

const selectConnector = async (item: DropDownItem) => {
  if (!item) {
    return
  }
  const id = `${item.itemValue}`
  if (!id || id === activeId.value) {
    return
  }

  if (id === ALL_LOB) {
    workspace.previousConnectorId = workspace.connectorId
    await router.push('/dashboard')
    return
  }
  workspace.setActiveConnector(id)

  if (router.currentRoute.value.path === '/dashboard') {
    router.push({
      path: '/',
      query: { lob: id },
    })

    return
  }

  const urlQuery = useUrlQueryHandler()
  urlQuery.addParamToUrlQuery('lob', id, true)
}

const el = ref(null as HTMLElement | null)
const { width } = useElementSize(el)
</script>

<template>
  <UiTransitionFade mode="out-in">
    <UiDropdown
      v-if="connectorItems.length"
      v-model="active"
      data-component="select_connector"
      :items="connectorItems"
      :min-width="width"
      @select="selectConnector"
    >
      <div
        v-if="primary"
        class="bg-primary border-primary-200 flex w-full flex-1 items-center truncate rounded-lg border px-3 py-2 text-white"
        :class="{
          'flex-row justify-between space-x-2': !mini,
          'flex-col': mini,
        }"
      >
        <div v-if="mini" class="subtitle-2">LOB</div>
        <div v-else class="subtitle-2 truncate">
          {{ active.label }}
        </div>
        <div class="i-ph-caret-down-bold h-4 w-4" />
      </div>
    </UiDropdown>
    <div v-else class="h-8" />
  </UiTransitionFade>
</template>
